import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import UUID from "vue-uuid";
import Loader from './views/components/Loader'
import Utente from './views/utenti/Utente'
import Categoria from './views/categorie/Categoria'
import Fase from './views/fasi/Fase'
import Esercizio from './views/esercizi/Esercizio'
import ModalFooterComponent from './views/base/ModalFooterComponent'
import CustomCard from './views/esercizi/CustomCard'
import CustomWidget from './views/widgets/CustomWidget'
import PromemoriaSingolo from './views/configurazione/PromemoriaSingolo'

Vue.component('Loader', Loader)
Vue.component('Utente', Utente)
Vue.component('Categoria', Categoria)
Vue.component('Fase', Fase)
Vue.component('Esercizio', Esercizio)
Vue.component('ModalFooterComponent', ModalFooterComponent)
Vue.component('CustomCard', CustomCard)
Vue.component('CustomWidget', CustomWidget)
Vue.component('PromemoriaSingolo', PromemoriaSingolo)
Vue.use(UUID);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
  }
})
