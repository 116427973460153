<template>
	<CRow>
		<CCol col="12">
			<h4>Dati utente</h4>
			<CForm>
				<CRow>
					<CCol
						:lg="userId ? 12: 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							label="Nome"
							autocomplete="name"
							:value="validatorObj.firstName.$model"
							@update:value="setFirstName"
							:isValid="requiredValidTouched(validatorObj.firstName)"
						>
						</CInput>
					</CCol>
					<CCol
						:lg="userId ? 12 : 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							label="Cognome"
							autocomplete="family-name"
							:value="validatorObj.lastName.$model"
							@update:value="setLastName"
							:isValid="requiredValidTouched(validatorObj.lastName)"
						>
						</CInput>
					</CCol>
				</CRow>
				<CRow>
					<CCol
						:lg="userId ? 12: 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							label="Email"
							autocomplete="email"
							:value="validatorObj.email.$model"
							@update:value="setEmail"
							:isValid="requiredValidTouched(validatorObj.email)"
							:readonly="userId ? true : false"
						>
						</CInput>
					</CCol>
					<CCol
						:lg="userId ? 12: 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							v-if="userId ? false : true"
							label="Password"
							type="password"
							autocomplete="current-password"
							:value="validatorObj.password.$model"
							@update:value="setPassword"
							:isValid="requiredValidTouched(validatorObj.password)"
							:readonly="userId ? true : false"
						>
						</CInput>
					</CCol>
				</CRow>
			</CForm>
			<div class="footer-actions">
				<template v-if="userId">
					<CButton
						color="sdAzure"
						@click="updateUser"
						:disabled="validatorObj.firstName.$invalid || validatorObj.lastName.$invalid"
					>Aggiorna</CButton>
				</template>
				<template v-else>
					<CButton
						color="sdAzure"
						@click="addUser"
						:disabled="validatorObj.firstName.$invalid || validatorObj.lastName.$invalid || validatorObj.email.$invalid || validatorObj.password.$invalid"
					>Crea</CButton>
				</template>
			</div>
		</CCol>
		<CCol
			v-if="userId"
			col="12"
		>
			<h4>Ripristina password</h4>
			<CForm>
				<CRow>
					<CCol
						:lg="userId ? 12: 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							label="Nuova Password"
							type="password"
							:value="validatorObj.password.$model"
							@update:value="setPassword"
							:isValid="requiredCharNumberMin8(validatorObj.password)"
						>
						</CInput>
					</CCol>
					<CCol
						:lg="userId ? 12: 6"
						class="mb-sm-2 mb-0"
					>
						<CInput
							label="Conferma Password"
							type="password"
							:value="validatorObj.repeatPassword.$model"
							@update:value="setRepeatPassword"
							:isValid="validSameAsTouched(validatorObj.repeatPassword, validatorObj.password)"
						>
						</CInput>
					</CCol>
				</CRow>
			</CForm>
			<div class="footer-actions">
				<CButton
					color="sdAzure"
					@click="resetUserPassword"
					:disabled="validatorObj.repeatPassword.$invalid || validatorObj.password.$invalid"
				>Modifica password</CButton>
			</div>

		</CCol>
	</CRow>
</template>

<script>

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { charNumberMin8, requiredCharNumberMin8, requiredValidTouched, validSameAsTouched, isValidUUID, sameAs, deleteModalOptions, pushToast } from "../../utilities/utilities";
import { mapActions } from "vuex";

export default {
	name: 'Utente',
	created() {
		if (this.userId) {
			// this.$store.commit('loadState', true);
			this.$store.dispatch("getUserById", this.userId).then(user => {
				this.lastName = user.lastName;
				this.firstName = user.firstName;
				this.email = user.email;
				this.password = user.password;
				// this.$store.commit('loadState', false);
			});
		}
	},
	validations() {
		return {
			firstName: { required },
			lastName: { required },
			email: { required, email },
			password: {
				required, passwordValidation: (value) => {
					return charNumberMin8(value);
				}
			},
			repeatPassword: {
				sameAs: sameAs('password')
			}
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			repeatPassword: "",
		}
	},
	computed: {
		userId() {
			const id = this.params && this.params.id;
			return id && isValidUUID(id) ? id : undefined;
		},
	},
	methods: {
		...mapActions(["addUser", "resetUserPassword", "updateUser"]),
		setFirstName(value) {
			this.validatorObj.firstName.$model = value;
		},
		setLastName(value) {
			this.validatorObj.lastName.$model = value;
		},
		setEmail(value) {
			this.validatorObj.email.$model = value;
		},
		setPassword(value) {
			this.validatorObj.password.$model = value;
		},
		setRepeatPassword(value) {
			this.validatorObj.repeatPassword.$model = value;
		},
		addUser() {
			this.$store.dispatch('addUser', {
				firstName: this.validatorObj.firstName.$model,
				lastName: this.validatorObj.lastName.$model,
				email: this.validatorObj.email.$model,
				password: this.validatorObj.password.$model,
			}).then(x => {
				if (x) {
					this.deleteModalOptions();
					this.pushToast({ title: 'Completato', body: 'Utente salvato', color: 'success' });
					this.params && this.params.callback();
				}
			});
		},
		resetUserPassword() {
			this.$store.dispatch('resetUserPassword', { password: this.validatorObj.password.$model, id: this.userId }).then(x => {
				this.pushToast({ title: 'Reset', body: 'Reset password completato', color: 'success' });
			});
		},
		updateUser() {
			this.$store.commit('loadState', true);
			this.$store.dispatch('updateUser', {
				id: this.userId,
				firstName: this.validatorObj.firstName.$model,
				lastName: this.validatorObj.lastName.$model,
			}).then(x => {
				if (x) {
					this.deleteModalOptions();
					this.pushToast({ title: 'Completato', body: 'Utente salvato', color: 'success' });
					this.params && this.params.callback();
				}
				this.$store.commit('loadState', false);
			});
		},
		requiredValidTouched,
		validSameAsTouched,
		isValidUUID,
		deleteModalOptions,
		pushToast,
		charNumberMin8,
		requiredCharNumberMin8
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>