

<template>
	<div>
		<CButton v-if="params && params.cancelButtonTitle"
			@click="$emit('clicked', 0)"
			color="sdRed"
		>{{params.cancelButtonTitle}}</CButton>
		<CButton v-if="params && params.confirmButtonTitle"
			@click="$emit('clicked', 1)"
			color="sdAzure"
		>{{params.confirmButtonTitle}}</CButton>
	</div>
</template>

<script>

export default {
	name: 'ModalFooterComponent',
  props: {
		params: {
			type: Object,
			default: undefined
		}
	},
  emits: {
		clicked: {
			type: MouseEvent,
		},
	},
}
</script>
