<template>
	<div
		class="cc-card"
		@click="$emit('clicked', params)"
	>
		<div class="cc-card-content">
			<div class="cc-card-icon">
				<!-- <CIcon :content="cursorMove" /> -->
				<i class="fas fa-arrows-alt"></i>
			</div>
			<div class="cc-card-body">
				<h4>{{params.name}}</h4>
				<p v-if="params.phasesCount"><strong>Numero fasi</strong>: {{params.phasesCount}}</p>
				<p v-if="params.exercisesCount"><strong>Numero esercizi</strong>: {{params.exercisesCount}}</p>
				<p v-if="params.description">
					<strong>Descrizione</strong>: {{params.description}}
				</p>
				<p v-if="params.videoUrl">
					<strong>Video url</strong>: {{params.videoUrl}}
				</p>
				<div class="cc-card-actions">
					<div
						class="cc-card-action edit"
						@click="editItem"
					>Modifica</div>
					<div
						class="cc-card-action delete"
						@click="deleteItem"
					>Cancella</div>
					<div
						v-if="canClone"
						class="cc-card-action clone"
						@click="cloneItem"
					>Clona</div>
				</div>
			</div>
			<div
				class="cc-card-img"
				v-if="params.imageUrl"
			>
				<CImg
					:src="params.imageUrl"
					class="mb-2"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { cilCursorMove } from '@coreui/icons'

export default {
	name: 'CustomCard',
	data() {
		return {
			cursorMove: cilCursorMove,
			categories: [],
			phases: [],
			exercises: [],
		}
	},
	props: {
		params: {
			type: Object,
			default() {
				return {};
			}
		},
		canClone: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		editItem($event) {
			$event.preventDefault();
			$event.stopImmediatePropagation();
			this.$emit('editItem');
		},
		deleteItem($event) {
			$event.preventDefault();
			$event.stopImmediatePropagation();
			this.$emit('deleteItem');
		},
		cloneItem($event) {
			$event.preventDefault();
			$event.stopImmediatePropagation();
			this.$emit('cloneItem');
		},
	},
	emits: {
		clicked: {
			type: Function
		},
		editItem: {
			type: Function
		},
		deleteItem: {
			type: Function
		},
		cloneItem: {
			type: Function
		},
	},
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/style";
.cc-card {
	padding: 10px;
	text-align: left;
	border: 1px solid #dbdbdb;
	border-radius: 5px;
	margin: 5px 0;
	background-color: $white;
	min-width: 200px;
	font-size: 0.8em;

	.cc-card-actions {
		display: inline-flex;
		// text-decoration: underline;

		& > div {
			background: #f3f3f3;
			padding: 4px;
			border-radius: 4px;
			&:not(:last-of-type) {
				margin-right: 0.5rem;
			}
		}
	}

	.cc-card-body {
		width: 100%;
	}

	.cc-card-img {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		img {
			width: 5rem;
			margin: 0 0 auto;
		}
	}

	.cc-card-action {
		cursor: pointer;
		&.delete {
			color: $sdRed;
		}
		&.edit {
			color: $sd-azure;
		}
		&.clone {
			color: $success-dark;
		}
	}

	&.active {
		background-color: $sdRed;
		color: $white;

		.cc-card-actions {
			& > div {
				background-color: $white;
			}
		}
	}

	.cc-card-content {
		display: inline-flex;
		align-items: flex-start;
		width: 100%;

		.cc-card-icon {
			cursor: grabbing;
			margin-right: 1rem;
			svg {
				width: 1.5rem;
				height: 1.5rem;
				font-size: 1.5rem;
				cursor: grabbing;

				&:hover,
				&:focus {
					path {
						fill: $sd-azure;
					}
				}
			}
		}
	}
}
.cc-card h3 {
	margin: 0px;
}
.cc-btn {
	background-color: #5cdb95;
	border: none;
	color: white;
	border-radius: 5px;
}

i {
	font-size: 1.25rem;
}
</style>