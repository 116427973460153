<template>
	<div class="lds-ring">
		<i class="fas fa-spinner fa-spin"></i>
	</div>
</template>

<script>
export default {
	name: 'Loader',
}
</script>

<style scoped lang="scss">
// Import Main styles for this application
@import "@/assets/scss/style";

i {
	font-size: 8rem;
	color: $sd-gray-light;
}
</style>
