import { uuid } from 'vue-uuid';
import { helpers } from "@vuelidate/validators"
import store from "../store"
import router from '../router'

export const httpLinkValidator = (value) => {
  let result = false;
  const expression = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
  const regex = new RegExp(expression);
  if (value.match(regex)) {
    result = true;
  }
  return result;
}

export const onlyHttpLinkValidator = (value) => {
  let result = false;
  const expression = 'https?:\/\/'
  const regex = new RegExp(expression);
  if (value.match(regex)) {
    result = true;
  }
  return result;
}


export const deleteModalOptions = () => {
  store.commit('setupModalOptions', undefined);
}

export const exitModalOptions = () => {
  store.commit('modalShow', false);
}

export const promemoriaOptionsGenerator = (max) => {
  const items = [];
  for (let i = 1; i <= max; i++) {
    items.push({ value: i, label: i })
  }
  return items;
}

export const pushToast = (toastOptions) => {
  toastOptions.id = newGuid();
  store.commit('pushToast', toastOptions);
}

export const afterDeleteUser = (action, userId) => {
  if (action === 1) {
    store.dispatch('deleteUser', userId).then(x => {
      router.push({ path: '/utenti' });
    });
  }
}

export const newUserUtilities = (callback) => {
  store.commit('setupModalOptions', {
    addContentClasses: 'modal-footer-hidden', title: 'Nuovo utente', component: "Utente", params: {
      callback: callback && callback.bind(this),
    },
  });
}

export const intToTimeLeft = (value) => {
  value = Number(value);
  const h = Math.floor(value / 3600);
  const m = Math.floor(value % 3600 / 60);
  const s = Math.floor(value % 3600 % 60);

  const hDisplay = h > 0 ? (h > 9 ? "" : "0") + h + ':' : "00:";
  const mDisplay = m > 0 ? (m > 9 ? "" : "0") + m + ':' : "00:";
  const sDisplay = s > 0 ? (s > 9 ? "" : "0") + s : "00";
  return hDisplay + mDisplay + sDisplay;
}

export const getAllenamentiModalOptions = (titleContext, callback) => {
  return {
    centered: true,
    size: 'md',
    color: 'sdYellow',
    title: 'Attenzione',
    body: `Confermando, le modifiche non salvate per ${titleContext}, andranno perse`,
    footerComponent: 'ModalFooterComponent',
    footerParams: {
      cancelButtonTitle: 'Indietro',
      confirmButtonTitle: 'Annulla le modifiche'
    },
    callback: callback.bind(this)
  }
};

export const getDeleteUserModalOptions = (firstName, lastName, callback) => {
  return {
    centered: true,
    size: 'md',
    color: 'dark',
    title: 'Cancellare utente?',
    body: `Conferma per cancellare l'utente: <strong>${firstName} ${lastName}</strong>`,
    footerComponent: 'ModalFooterComponent',
    footerParams: {
      cancelButtonTitle: 'Annulla',
      confirmButtonTitle: 'Conferma'
    },
    callback: callback.bind(this)
  }
};

export const getDeletePhaseModalOptions = (phaseName, callback) => {
  return {
    centered: true,
    size: 'md',
    color: 'dark',
    title: 'Cancellare fase?',
    body: `Conferma per cancellare la fase: <strong>${phaseName}</strong> e tutti gli esercizi associati`,
    footerComponent: 'ModalFooterComponent',
    footerParams: {
      cancelButtonTitle: 'Annulla',
      confirmButtonTitle: 'Conferma'
    },
    callback: callback.bind(this)
  }
};

export const getDeleteCategoryModalOptions = (categoryName, callback) => {
  return {
    centered: true,
    size: 'md',
    color: 'dark',
    title: 'Cancellare categoria?',
    body: `Conferma per cancellare la categoria: <strong>${categoryName}</strong> e tutte le sottocategorie associate`,
    footerComponent: 'ModalFooterComponent',
    footerParams: {
      cancelButtonTitle: 'Annulla',
      confirmButtonTitle: 'Conferma'
    },
    callback: callback.bind(this)
  }
};

export const getDeleteExerciseModalOptions = (exerciseName, callback) => {
  return {
    centered: true,
    size: 'md',
    color: 'dark',
    title: 'Cancellare esercizio?',
    body: `Conferma per cancellare l'esercizio: <strong>${exerciseName}</strong>?`,
    footerComponent: 'ModalFooterComponent',
    footerParams: {
      cancelButtonTitle: 'Annulla',
      confirmButtonTitle: 'Conferma'
    },
    callback: callback.bind(this)
  }
};

export const sameAs = (fieldComparison) => function (value, _this) {
  return !helpers.req(value) || value === _this[fieldComparison];
};

// MOCK - TO TEST
export const fetchApi = (api, options, bearer = null) => {
  return new Promise(async (resolve, reject) => {
    let fetchOptions = options.headers = {
      'authorization': `Bearer ${bearer}`,
      'content-type': 'application/x-www-form-urlencoded'
    };
    const response = await fetch(api, fetchOptions);
    const result = await response.json();
    resolve(result);
  });
}

export const requiredValidTouched = (validator) => {
  let result = null;
  if (validator.$dirty) {
    result = !validator.$invalid ? true : false;
  }
  return result;
}

export const validSameAsTouched = (validator, compareValidator) => {
  let result = null;
  if (validator.$dirty) {
    result = !validator.$invalid && !compareValidator.$invalid && compareValidator.$model === validator.$model ? true : false;
  }
  return result;
}

export const requiredCharNumberMin8 = (validator) => {
  let result = null;
  if (validator.$dirty) {
    result = !!charNumberMin8(validator.$model);
  }
  return result;
}

export const charNumberMin8 = function (value) {
  if (value.toUpperCase() === value) return false;
  if (value.toLowerCase() === value) return false;
  if (!/\d/.test(value)) return false;
  if (value.length <= 8) return false;
  return true;
};

export const newGuid = () => {
  return uuid.v4();
}

export const isValidUUID = (uuid) => {
  let result = false;
  if (uuid.length > 0) {
    if ((/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(uuid)) {
      result = true;
    }
  }
  return result;
}