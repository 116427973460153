<template>
	<CRow>
		<CCol col="12">
			<CRow>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInput
						label="Nome"
						:value="validatorObj.name.$model"
						@update:value="setName"
						:isValid="requiredValidTouched(validatorObj.name)"
					>
					</CInput>
				</CCol>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInputFile
						label="URL Immagine"
						horizontal
						custom
						:placeholder="oldImageName === undefined ? 'Seleziona file' : validatorObj.imageUrl.$model"
						@change="onUrlChange"
					/>
				</CCol>
			</CRow>
			<div class="footer-actions">
				<CButton
					color="sdAzure"
					@click="eventHandler"
					:disabled="validatorObj.name.$invalid || validatorObj.imageUrl.$invalid"
				>{{activePhase ? 'Aggiorna' : 'Crea'}}</CButton>
			</div>
		</CCol>
	</CRow>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, isValidUUID, deleteModalOptions, pushToast, exitModalOptions } from "../../utilities/utilities";
import { apiUrl } from "../../store";
import { mapActions } from "vuex";

export default {
	name: 'Fase',
	created() {
		if (this.activePhase) {
			this.$store.commit('loadState', true);
			this.$store.dispatch("getPhaseById", { categoryId: this.activeCategory, phaseId: this.activePhase }).then(phase => {
				this.name = phase.name;
				this.imageUrl = phase.imageUrl;
				this.$store.commit('loadState', false);
			});
		}
	},
	validations() {
		return {
			name: { required },
			imageUrl: { required },
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			name: "",
			imageUrl: undefined,
			oldImageName: undefined,
		}
	},
	computed: {
		activePhase() {
			return this.params && this.params.isNew ? undefined : this.$store.state.activePhase;
		},
		activeCategory() {
			return this.$store.state.activeCategory;
		},
		lastPhaseRank() {
			return this.$store.state.lastPhaseRank;
		},
		phases() {
			return this.$store.state.phases;
		}
	},
	methods: {
		...mapActions(["addPhase", "updatePhase"]),
		setName(value) {
			this.validatorObj.name.$model = value;
		},
		onUrlChange(value) {
			const file = value[0];
			const fileName = file.name;
			if (!this.oldImageName || this.oldImageName !== fileName) {
				this.$store.commit('loadState', true);
				this.oldImageName = fileName
				const data = new FormData();
				data.append('asset', file)
				this.$store.dispatch('addAsset', data).then(imageData => {
					this.$store.commit('loadState', false);
					this.pushToast({ title: 'Completato', body: 'File caricato', color: 'success' });
					this.validatorObj.imageUrl.$model = `${apiUrl}/${imageData.url}`;
				});
			}
		},
		eventHandler() {
			this.activePhase ? this.updatePhase() : this.addPhase();
		},
		addPhase() {
			this.$store.dispatch('addPhase', {
				name: this.validatorObj.name.$model,
				imageUrl: this.validatorObj.imageUrl.$model,
				rank: this.lastPhaseRank && this.lastPhaseRank + 1 || 1,
				categoryId: this.activeCategory,
			}).then(x => {
				this.$store.commit('pushNewPhase', x);
				this.$store.commit('updateActiveCategoryCount', { id: this.activeCategory, action: 1 });
				this.deleteModalOptions();
				this.pushToast({ title: 'Completato', body: 'Fase aggiunta', color: 'success' });
			});
		},
		updatePhase() {
			this.$store.dispatch('updatePhase', {
				name: this.validatorObj.name.$model,
				imageUrl: this.validatorObj.imageUrl.$model,
				categoryId: this.activeCategory,
				phaseId: this.activePhase
			}).then(data => {
				this.$store.commit('updateExistingPhase', { index: this.phases.items.findIndex(x => x.id === data.id), item: data });
				this.deleteModalOptions();
				this.pushToast({ title: 'Aggiornato', body: 'Fase aggiornata', color: 'success' });
			});
		},
		requiredValidTouched,
		isValidUUID,
		deleteModalOptions,
		pushToast,
		exitModalOptions
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>