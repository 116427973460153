<template>
	<CRow>
		<CCol
			col="12"
			lg="12"
		>
			<CRow>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInput
						label="Nome"
						:value="validatorObj.name.$model"
						@update:value="setName"
						:isValid="requiredValidTouched(validatorObj.name)"
					>
					</CInput>
				</CCol>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInput
						label="Numero ripetizioni"
						:value="validatorObj.repetitions.$model"
						@update:value="setRepetitions"
						:isValid="requiredValidTouched(validatorObj.repetitions)"
					>
					</CInput>
				</CCol>
			</CRow>
			<CRow>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CTextarea
						label="Descrizione"
						:value="validatorObj.description.$model"
						@update:value="setDescription"
						:isValid="requiredValidTouched(validatorObj.description)"
					>
					</CTextarea>
				</CCol>
				<CCol
					lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInput
						label="URL Video"
						:value="validatorObj.videoUrl.$model"
						@update:value="setVideoUrl"
						:isValid="requiredValidTouched(validatorObj.videoUrl)"
					>
					</CInput>
				</CCol>
			</CRow>
			<div class="footer-actions">
				<CButton
					color="sdAzure"
					@click="eventHandler"
					:disabled="validatorObj.name.$invalid || validatorObj.repetitions.$invalid || validatorObj.videoUrl.$invalid || validatorObj.description.$invalid"
				>{{activeExercise ? 'Aggiorna' : 'Crea'}}</CButton>

			</div>
		</CCol>
	</CRow>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, isValidUUID, deleteModalOptions, onlyHttpLinkValidator, pushToast } from "../../utilities/utilities";
import { mapActions } from "vuex";

export default {
	name: 'Esercizio',
	created() {
		if (this.activeExercise) {
			this.$store.commit('loadState', true);
			this.$store.dispatch("getExerciseById", { exerciseId: this.activeExercise, categoryId: this.activeCategory, phaseId: this.activePhase }).then(exercise => {
				this.repetitions = exercise.repetitions;
				this.name = exercise.name;
				this.description = exercise.description;
				this.videoUrl = exercise.videoUrl;
				this.$store.commit('loadState', false);
			});
		}
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	validations() {
		return {
			name: { required },
			repetitions: { required },
			description: { required },
			videoUrl: { onlyHttpLinkValidator }
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	data() {
		return {
			name: "",
			repetitions: "",
			description: "",
			videoUrl: "",
		}
	},
	computed: {
		activeExercise() {
			return this.params && this.params.isNew ? undefined : this.$store.state.activeExercise;
		},
		activePhase() {
			return this.$store.state.activePhase;
		},
		activeCategory() {
			return this.$store.state.activeCategory;
		},
		lastExerciseRank() {
			return this.$store.state.lastExerciseRank;
		},
		exercises() {
			return this.$store.state.exercises;
		}
	},
	methods: {
		...mapActions(["addExercise", "updateExercise"]),
		setName(value) {
			this.validatorObj.name.$model = value;
		},
		setRepetitions(value) {
			this.validatorObj.repetitions.$model = value;
		},
		setDescription(value) {
			this.validatorObj.description.$model = value;
		},
		setVideoUrl(value) {
			this.validatorObj.videoUrl.$model = value;
		},
		eventHandler() {
			this.activeExercise ? this.updateExercise() : this.addExercise();
		},
		addExercise() {
			this.$store.dispatch('addExercise', {
				name: this.validatorObj.name.$model,
				repetitions: this.validatorObj.repetitions.$model,
				description: this.validatorObj.description.$model,
				videoUrl: this.validatorObj.videoUrl.$model,
				categoryId: this.activeCategory,
				phaseId: this.activePhase,
				rank: this.lastExerciseRank && this.lastExerciseRank + 1 || 1,
			}).then(x => {
				this.$store.commit('pushNewExercise', x);
				this.$store.commit('updateActivePhaseCount', { id: this.activePhase, action: 1 });
				this.deleteModalOptions();
				this.pushToast({ title: 'Completato', body: 'Esercizio aggiunto', color: 'success' });
			});
		},
		updateExercise() {

			this.$store.dispatch('updateExercise', {
				name: this.validatorObj.name.$model,
				repetitions: this.validatorObj.repetitions.$model,
				description: this.validatorObj.description.$model,
				videoUrl: this.validatorObj.videoUrl.$model,
				categoryId: this.activeCategory,
				phaseId: this.activePhase,
				exerciseId: this.activeExercise
			}).then(data => {
				this.$store.commit('updateExistingExercise', { index: this.exercises.items.findIndex(x => x.id === data.id), item: data });
				this.deleteModalOptions();
				this.pushToast({ title: 'Aggiornato', body: 'Esercizio aggiornato', color: 'success' });
			});
		},
		requiredValidTouched,
		isValidUUID,
		deleteModalOptions,
		pushToast
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>