<template>
	<CRow>
		<CCol
			col="12"
			sm="12"
			md="3"
			lg="3"
			xl="3"
			xxl="3"
			class="mb-sm-2 mb-0"
		>
			<CSelect
				label="Giorno"
				:value="validatorObj.daysOfWeeksSelected.$model"
				@update:value="updateWeek"
				:options="params && params.daysOfWeekOptions"
				placeholder="Seleziona..."
			/>
		</CCol>
		<CCol
			col="6"
			sm="4"
			md="3"
			lg="3"
			xl="3"
			xxl="3"
			class="mb-sm-2 mb-0"
		>
			<CSelect
				label="Ore"
				:value="validatorObj.hoursSelected.$model"
				@update:value="updateHour"
				:options="params && params.hoursOptions"
				placeholder="Seleziona..."
			/>
		</CCol>
		<CCol
			col="6"
			sm="4"
			md="3"
			lg="3"
			xl="3"
			xxl="3"
			class="mb-sm-2 mb-0"
		>
			<CSelect
				label="Minuti"
				:value="validatorObj.minutesSelected.$model"
				@update:value="updateMinute"
				:options="params && params.minutesOptions"
				placeholder="Seleziona..."
			/>
		</CCol>
		<CCol
			col="12"
			sm="4"
			md="3"
			lg="3"
			xl="3"
			xxl="3"
			class="mb-sm-2 mb-0"
		>
			<div class="reminder-actions">
				<CButton
					v-if="params && params.isLast"
					@click="addReminder()"
					color="sdAzure"
					class="px-3"
					:disabled="validatorObj.daysOfWeeksSelected.$invalid || validatorObj.hoursSelected.$invalid || validatorObj.minutesSelected.$invalid"
				><i class="fas fa-plus"></i>
				</CButton>
				<CButton
					@click="$emit('deleteReminder')"
					color="sdRed"
					class="px-3"
				><i class="fas fa-trash"></i>
				</CButton>
			</div>
		</CCol>
	</CRow>
</template>

<script>

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, validSameAsTouched } from "../../utilities/utilities";

export default {
	name: 'PromemoriaSingolo',
	created() {
		this.$nextTick(() => {
			this.validatorObj.daysOfWeeksSelected.$model = this.params && this.params.model.dayOfWeek;
			this.validatorObj.hoursSelected.$model = this.params && this.params.model.hour;
			this.validatorObj.minutesSelected.$model = this.params && this.params.model.minute;
		})
	},
	validations() {
		return {
			daysOfWeeksSelected: { required },
			hoursSelected: { required },
			minutesSelected: { required },
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			hoursSelected: undefined,
			minutesSelected: undefined,
			daysOfWeeksSelected: undefined,
		}
	},
	methods: {
		addReminder() {
			this.$emit('addReminder');
		},
		updateWeek(value) {
			this.params && (this.validatorObj.daysOfWeeksSelected.$model = this.params.model['dayOfWeek'] = value);
		},
		updateHour(value) {
			this.params && (this.validatorObj.hoursSelected.$model = this.params.model['hour'] = value);
		},
		updateMinute(value) {
			this.params && (this.validatorObj.minutesSelected.$model = this.params.model['minute'] = value);
		},
		requiredValidTouched,
		validSameAsTouched,
	},
	emits: {
		deleteReminder: {
			type: Function
		},
		addReminder: {
			type: Object
		}
	},
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reminder-actions {
	text-align: right;
	& > button {
		&:not(:first-of-type) {
			margin-left: 1rem;
		}
	}
}

@media screen and (min-width: 576px) {
	.reminder-actions {
		text-align: right;
		& > button {
			margin-top: 1.8rem;
		}
	}
}
</style>