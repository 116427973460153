<template>
	<div class="custom-widget">
		<CWidgetDropdown
			:header="title"
			:text="value"
			:color="color"
		/>
	</div>
</template>

<script>

export default {
	name: 'CustomWidget',
	props: {
		color: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>