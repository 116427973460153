import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// // Views - Pages
const Login = () => import('@/views/pages/Login')

// Real
const Utenti = () => import('@/views/utenti/Utenti')
const Allenamenti = () => import('@/views/esercizi/Allenamenti')
const ConsigliNutrizionali = () => import('@/views/consigli-nutrizionali/ConsigliNutrizionali')
const UtenteDettaglio = () => import('@/views/utenti/UtenteDettaglio')
const Notifiche = () => import('@/views/configurazione/Notifiche')
const Promemoria = () => import('@/views/configurazione/Promemoria')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

const loginPageName = 'login';

router.beforeEach((to, from, next) => {
  const isLoginPage = to.name === loginPageName;
  if (!isLoginPage && !store.getters.isValidToken) {
    next({ name: loginPageName });
  } else if (isLoginPage && store.getters.isValidToken) {
    next({ name: 'Home' });
  } else {
    next();
  }
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/utenti',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'utenti',
          meta: {
            label: 'Utenti'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Anagrafica utenti',
              component: Utenti
            },
            {
              path: ':id',
              meta: {
                label: 'Utente'
              },
              name: 'Utente',
              component: UtenteDettaglio
            }
          ]
        },
        {
          path: 'allenamenti',
          meta: {
            label: 'Allenamenti'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Allenamenti',
              component: Allenamenti
            }
          ]
        },
        {
          path: 'consigli-nutrizionali',
          meta: {
            label: 'Consigli nutrizionali'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Consigli Nutrizionali',
              component: ConsigliNutrizionali
            }
          ]
        },
        {
          path: 'configurazione',
          redirect: '/configurazione/notifiche',
          name: 'Configurazione',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'notifiche',
              name: 'Notifiche',
              component: Notifiche
            },
            {
              path: 'promemoria',
              name: 'Promemoria',
              component: Promemoria
            },
          ]
        },
      ]
    },
    {
      path: '/login',
      meta: {
        label: 'Login'
      },
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '',
          name: 'login',
          component: Login
        }
      ]
    },
    {
      path: '*',
      redirect: '/utenti',
    },
  ]
}

export default router;