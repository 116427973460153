<template>
	<div>
		<router-view></router-view>
		<div
			class="loader"
			v-show="loader"
		>
			<loader></loader>
		</div>
		<CModal
			:title="modalOptions && modalOptions.title"
			:show.sync="modalShow"
			:centered="modalOptions && modalOptions.centered ? modalOptions.centered : 'centered'"
			:color="modalOptions && modalOptions.color ? modalOptions.color : 'dark'"
			:size="modalOptions && modalOptions.size ? modalOptions.size : 'lg'"
			:addContentClasses="modalOptions && modalOptions.addContentClasses"
		>
			<template v-if="modalOptions && modalOptions.body">
				<div v-html="modalOptions.body"></div>
			</template>
			<component
				:is="modalOptions.component"
				:params="modalOptions.params"
				v-if="modalOptions && modalOptions.component"
			></component>
			<template #footer>
				<component
					:is="modalOptions.footerComponent"
					:params="modalOptions.footerParams"
					@clicked="footerButtonClicked"
					v-if="modalOptions && modalOptions.footerComponent"
				></component>
			</template>
		</CModal>
		<CToaster
			:autohide="3000"
			position="top-right"
		>
			<template v-for="toast in fixedToasts">
				<CToast
					:key="'toast-' + toast.id"
					:show="true"
					:header="toast.title"
					:color="toast.color"
				>
					{{toast.body}}
				</CToast>
			</template>
		</CToaster>
	</div>
</template>

<script>

import { deleteModalOptions } from "./utilities/utilities"

export default {
	name: 'App',
	data() {
		return {
			actionClicked: undefined,
		}
	},
	computed: {
		loader() {
			return this.$store.state.loader;
		},
		modalOptions() {
			return this.$store.state.modalOptions;
		},
		modalShow: {
			get() {
				return this.$store.state.modalShow;
			},
			set() {
				this.modalOptions.callback && this.modalOptions.callback(this.actionClicked);
				this.deleteModalOptions();
			}
		},
		fixedToasts() {
			return this.$store.state.fixedToasts;
		}
	},
	methods: {
		footerButtonClicked(action) {
			this.actionClicked = action;
			this.modalShow = false
		},
		deleteModalOptions
	}
}
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

$fa-font-path: "assets/scss/font-awesome/webfonts/";
$fa-font-display: auto;

// FONT AWESOME IMPORTS
// @font-face {
//   font-family: 'Font Awesome 5 Brands';
//   font-style: normal;
//   font-weight: normal;
//   font-display: $fa-font-display;
//   src: url('#{$fa-font-path}fa-brands-400.eot');
//   src: url('#{$fa-font-path}fa-brands-400.eot?#iefix') format('embedded-opentype'),
//   url('#{$fa-font-path}fa-brands-400.woff2') format('woff2'),
//   url('#{$fa-font-path}fa-brands-400.woff') format('woff'),
//   url('#{$fa-font-path}fa-brands-400.ttf') format('truetype'),
//   url('#{$fa-font-path}fa-brands-400.svg#fontawesome') format('svg');
// }

// .fab {
//   font-family: 'Font Awesome 5 Brands';
// }

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
	font-display: $fa-font-display;
	src: url("#{$fa-font-path}fa-solid-900.eot");
	src: url("#{$fa-font-path}fa-solid-900.eot?#iefix")
			format("embedded-opentype"),
		url("#{$fa-font-path}fa-solid-900.woff2") format("woff2"),
		url("#{$fa-font-path}fa-solid-900.woff") format("woff"),
		url("#{$fa-font-path}fa-solid-900.ttf") format("truetype"),
		url("#{$fa-font-path}fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: "Font Awesome 5 Pro";
	font-weight: 900;
}

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400;
	font-display: $fa-font-display;
	src: url("#{$fa-font-path}fa-regular-400.eot");
	src: url("#{$fa-font-path}fa-regular-400.eot?#iefix")
			format("embedded-opentype"),
		url("#{$fa-font-path}fa-regular-400.woff2") format("woff2"),
		url("#{$fa-font-path}fa-regular-400.woff") format("woff"),
		url("#{$fa-font-path}fa-regular-400.ttf") format("truetype"),
		url("#{$fa-font-path}fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
}

// FONT AWESOME IMPORTS

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 999999999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(20, 20, 20, 0.8);
	overflow: hidden;
	pointer-events: none;
}

.mx-datepicker {
	width: 100% !important;
}

.modal-footer-hidden {
	.modal-footer {
		display: none;
	}
}

.modal-footer {
	> div {
		button {
			&:last-of-type {
				margin-left: 1rem;
			}
		}
	}
}

.footer-actions {
	margin-top: 1rem;
	text-align: right;
	& > button {
		&:not(:first-of-type) {
			margin-left: 1rem;
		}
	}
}

.custom-file-label::after {
	content: "Carica";
}
.allenamenti {
	.draggable-component {
		display: inline-flex;
		width: 100%;
		max-width: 32%;
		&:not(:last-of-type) {
			margin-right: 2%;
		}
	}
	.draggable-header {
		display: flex;
		justify-content: space-between;
	}
	.draggable-category {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		padding: 1.5rem;
		background: gainsboro;
		width: 100%;
		border-radius: 8px;
		margin-bottom: 30px;

		& > button {
			margin-top: 1.5rem;
		}

		.header-buttons {
			display: flex;
			align-items: center;

			& > button {
				&.disabled {
					pointer-events: none;

					svg {
						& > * {
							fill: $sd-gray;
						}
					}
				}
			}

			.lds-ring {
				width: 1.5rem;
				height: 1.5rem;

				div {
					width: 24px;
					height: 24px;
					margin: 0;
					border: 2px solid $sd-azure;
					border-color: $sd-azure transparent transparent transparent;
				}
			}

			& > * {
				&:not(:first-child) {
					margin-left: 0.5rem;
				}
			}

			button {
				padding: 0;
			}

			svg {
				width: 1.5rem;
				height: 1.5rem;
				font-size: 1.5rem;
				cursor: pointer;

				&:hover,
				&:focus {
					& > * {
						fill: $sd-azure;
					}
				}
			}
		}
	}
}

.modal-danger {
	.close {
		color: $white;
	}
}

.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.table {
	tbody {
		tr {
			position: relative;
		}
	}
}

.dropdown-actions {
	.dropdown-menu {
		& > button {
			&.last {
				margin-left: 1rem;
			}
		}
	}
}

@media screen and (max-width: 767px) {

	.pagination-lg .page-link {
		padding: 0.25rem 0.5rem;
	}
	.dropdown-actions {
		.dropdown-menu {
			padding: 1rem 0;
			> button {
				margin: 0 auto 0.5rem !important;
				display: block;

				&:last-of-type {
					margin: 0 auto !important;
				}
			}
		}
	}

	.table-actions {
		&.dropdown-actions {
			position: absolute;
			top: 5px;
		}
	}
}
@media screen and (min-width: 768px) {
	.button-label-xs {
		display: none;
	}
	.dropdown-actions {
		.dropdown-menu {
			padding: 0;
			position: inherit;
			display: block;
			min-width: auto;
			border: none;
			background: transparent;
		}
		.dropdown {
			& > button {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	.allenamenti {
		.draggable-component {
			max-width: 100%;
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
	.allenamenti {
		.draggable-component {
			max-width: 48%;
		}
	}
}
</style>
