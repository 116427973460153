import { newGuid } from '../utilities/utilities'

export const loginMock = async () =>
  JSON.stringify({
    user: {
      email: 'n.lassandro@tekmore.it',
    },
  });

export const logoutMock = async () =>
  JSON.stringify({
    result: true
  });

export const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    resolve (JSON.stringify({token:'Abdullah', expiration: new Date(new Date().getTime() + 180000000).getTime()}));
  });
}

export const getUsers = async () => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify([
      { id: newGuid(), firstName: 'Samppa', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Dio', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Nosturi', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Barabba', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Urulla', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Alonzi', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Sedermosci', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Babba', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Uzzio', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Aonna', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Ragazzi', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
      { id: newGuid(), firstName: 'Gesu', lastName: 'Nori', email: 'utente@utente.it', createdDate: new Date('2021-08-27T15:24:48.691Z') },
    ]));
  });
}

export const getExercises = async () => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify([
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Trazioni', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Test', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Urulla', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Trazioni', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Test', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Urulla', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Trazioni', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Test', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Urulla', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Trazioni', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Test', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Urulla', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Trazioni', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Test', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Urulla', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
    ]));
  });
}

export const getCategories = async () => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify([
      { id: newGuid(), name: 'Categoria 1', rank: 0 },
      { id: newGuid(), name: 'Categoria 2', rank: 0 },
      { id: newGuid(), name: 'Categoria 3', rank: 0 },
      { id: newGuid(), name: 'Categoria 4', rank: 0 },
      { id: newGuid(), name: 'Categoria 5', rank: 0 },
      { id: newGuid(), name: 'Categoria 6', rank: 0 },
      { id: newGuid(), name: 'Categoria 7', rank: 0 },
      { id: newGuid(), name: 'Categoria 8', rank: 0 },
      { id: newGuid(), name: 'Categoria 9', rank: 0 },
      { id: newGuid(), name: 'Categoria 10', rank: 0 },
      { id: newGuid(), name: 'Categoria 11', rank: 0 },
      { id: newGuid(), name: 'Categoria 12', rank: 0 },
      { id: newGuid(), name: 'Categoria 13', rank: 0 },
    ]));
  });
}

export const getPhases = async () => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify([
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 1', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 3', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 4', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 5', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 6', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 3', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 4', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 5', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 6', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 3', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 4', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 5', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 6', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 3', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 4', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 5', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 6', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 3', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 4', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 5', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
    ]));
  });
}


export const getUserById = async (id) => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify(
      { id: newGuid(), firstName: 'Samppa', lastName: 'Nori', email: 'utente@utente.it' },
    ));
  });
}

export const getPhaseById = async (id) => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify(
      { id: newGuid(), exerciseCategoryId: newGuid(), name: 'Fase 1', rank: 0, imageUrl: "http://www.opesitalia.it/wp-content/uploads/2020/04/jonathan-borba-lrQPTQs7nQQ-unsplash-min-800x500_c.jpg" },
    ));
  });
}

export const getCategoryById = async (id) => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify(
      { id: newGuid(), name: 'Categoria 1', rank: 0 },
    ));
  });
}

export const getExerciseById = async (id) => {
  return new Promise((resolve, reject) => {
    resolve(JSON.stringify(
      { id: newGuid(), exerciseCategoryPhaseId: newGuid(), name: 'Rotazioni delle spalle', repetitions: 10, description: "10 avanti - 10 indietro", videoUrl: "https://www.youtube.com/embed/xcJtL7QggTI" },
    ));
  });
}