import Vue from 'vue'
import Vuex from 'vuex'
import { logoutMock } from './mocks/mock-data'
import jwt_decode from "jwt-decode";
Vue.use(Vuex)

export const apiUrl = process.env.VUE_APP_API_LOCATION;
const apiUrlv1 = `${apiUrl}/v1`
const loginApi = `${apiUrlv1}/auth/token`;
const restUser = `${apiUrlv1}/users`;
const restCategory = `${apiUrlv1}/categories`;
const restPhases = (categoryId) => `${apiUrlv1}/categories/${categoryId}/phases`;
const saveCategoriesApi = `${apiUrlv1}/batch/categories/ranks`;
const savePhasesApi = `${apiUrlv1}/batch/phases/ranks`;
const saveExercisesApi = `${apiUrlv1}/batch/exercises/ranks`;
const restAdvice = `${apiUrlv1}/nutritionaladvice`;
const restNotifications = `${apiUrlv1}/notifications/all`;
const restReminders = `${apiUrlv1}/reminders`;
const restAssets = `${apiUrlv1}/assets`;
const restExercises = (categoryId, phaseId) => `${apiUrlv1}/categories/${categoryId}/phases/${phaseId}/exercises`;

const setToken = (data) => {
  if (data) {
    localStorage.setItem('access-token', JSON.stringify(data))
  } else {
    localStorage.removeItem('access-token');
  }
  return data;
}

const addAuthorizationToken = (token, data) => {
  data['authorization'] = `Bearer ${token}`;
  return data;
}

const removeProperties = (obj, props) => {
  if (props && props.length > 0) {
    props.forEach(p => {
      delete obj[p];
    });
  }
  return obj;
}

const getHelperSingle = (apiUrl, payload) => {
  return `${apiUrl}/${payload}`;
}

const getHelperProps = (apiUrl, payload) => {
  let getBuilder = '';
  const objLenght = Object.keys(payload).length;
  let objCounter = 1;
  for (let prop in payload) {
    const propValue = payload[prop];
    if (propValue !== undefined && propValue !== "") {
      objCounter > 1 && objCounter <= objLenght && (getBuilder += '&');
      getBuilder += `${prop}=${propValue}`;
    }
    objCounter++;
  }
  return `${apiUrl}?${getBuilder}`;
}

const state = {
  subheaderButton: undefined,
  modalOptions: undefined,
  modalShow: false,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  token: localStorage.getItem('access-token') && setToken(JSON.parse(localStorage.getItem('access-token'))) || '',
  tokenData: localStorage.getItem('access-token') && jwt_decode(localStorage.getItem('access-token')) || '',
  status: '',
  loader: false,
  lastCategoryRank: undefined,
  lastPhaseRank: undefined,
  lastExerciseRank: undefined,
  categories: {
    isDirty: false,
    items: [],
  },
  phases: {
    isDirty: false,
    items: [],
  },
  exercises: {
    isDirty: false,
    items: [],
  },
  activeCategory: undefined,
  activePhase: undefined,
  activeExercise: undefined,
  fixedToasts: [],
}

const getters = {
  isValidToken: (state) => {
    return !!state.token && state.tokenData.role === "admin";
  },
  authStatus: state => state.status
}

const mutations = {
  setupSubheaderButton(state, payload) {
    state.subheaderButton = payload;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebaexitModalOptionspened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  loadState(state, payload) {
    state.loader = payload;
  },
  // MODAL SECTION
  setupModalOptions(state, payload) {
    this.commit('modalShow', payload ? true : false);
    setTimeout(() => state.modalOptions = payload, payload ? 0 : 250);
  },
  modalShow(state, payload) {
    state.modalShow = payload;
  },
  // END MODAL
  //TOKEN SECTION
  setToken(state, payload) {
    const token = setToken(payload);
    this.commit('setTokenData', token ? jwt_decode(token) : undefined);
    state.token = token;
  },
  setTokenData(state, payload) {
    state.tokenData = payload;
  },
  // END TOKEN
  // CATEGORY SECTION
  updateActiveCategoryCount(state, payload) {
    const targetedCategory = state.categories.items.find(x => x.id === payload.id);
    const count = targetedCategory.phasesCount;
    if (payload.action === -1) {
      targetedCategory.phasesCount--;
    } else {
      if (count !== undefined && count !== null) {
        targetedCategory.phasesCount++
      } else {
        targetedCategory.phasesCount = 0;
      }
    }
  },
  setCategories(state, payload) {
    state.categories = payload;
  },
  setLastCategoryRank(state, payload) {
    state.lastCategoryRank = payload;
  },
  pushNewCategory(state, payload) {
    state.categories.items.push(payload);
    this.commit('setLastCategoryRank', payload.rank);
  },
  updateExistingCategory(state, payload) {
    payload.item.phasesCount = state.categories.items[payload.index].phasesCount;
    state.categories.items.splice(payload.index, 1, payload.item);
  },
  removeCategory(state, payload) {
    state.categories.items.splice(payload, 1);
  },
  setActiveCategory(state, payload) {
    state.activeCategory = payload;
  },
  // END CATEGORY
  // PHASE SECTION
  updateActivePhaseCount(state, payload) {
    const targetedPhase = state.phases.items.find(x => x.id === payload.id);
    const count = targetedPhase.exercisesCount;
    if (payload.action === -1) {
      targetedPhase.exercisesCount--;
    } else {
      if (count !== undefined && count !== null) {
        targetedPhase.exercisesCount++
      } else {
        targetedPhase.exercisesCount = 0;
      }
    }
  },
  setPhases(state, payload) {
    state.phases = payload;
  },
  setLastPhaseRank(state, payload) {
    state.lastPhaseRank = payload;
  },
  pushNewPhase(state, payload) {
    state.phases.items.push(payload);
    this.commit('setLastPhaseRank', payload.rank);
  },
  updateExistingPhase(state, payload) {
    payload.item.exercisesCount = state.phases.items[payload.index].exercisesCount;
    state.phases.items.splice(payload.index, 1, payload.item);
  },
  removePhase(state, payload) {
    state.phases.items.splice(payload, 1);
  },
  setActivePhase(state, payload) {
    state.activePhase = payload;
  },
  // END PHASE
  // EXERCISE SECTION
  setExercises(state, payload) {
    state.exercises = payload;
  },
  setLastExerciseRank(state, payload) {
    state.lastExerciseRank = payload;
  },
  pushNewExercise(state, payload) {
    state.exercises.items.push(payload);
    this.commit('setLastExerciseRank', payload.rank);
  },
  updateExistingExercise(state, payload) {
    state.exercises.items.splice(payload.index, 1, payload.item);
  },
  removeExercise(state, payload) {
    state.exercises.items.splice(payload, 1);
  },
  setActiveExercise(state, payload) {
    state.activeExercise = payload;
  },
  // END EXERCISE
  pushToast(state, payload) {
    state.fixedToasts.push(payload);
  }
}

const actions = {
  userLogin: ({ commit, getters }, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(loginApi, {
          body: JSON.stringify(payload), method: "post", headers: {
            'content-type': 'application/json'
          }
        });
        const result = await response.json();
        const accessToken = result && result.accessToken;
        commit("setToken", accessToken);
        resolve(!!accessToken);
      } catch (err) {
        reject(err);
      }
    });
  },
  logout: ({ commit }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await logoutMock();
        const result = response && JSON.parse(response);
        if (result && result.result) {
          commit("setToken");
          resolve(response);
        }
      } catch (err) {
        commit('auth_error', err);
        reject(err);
      }
    })
  },
  // USER
  getUserById: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restUser, payload), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  getUsers: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperProps(restUser, payload), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  deleteUser: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restUser, payload), {
          method: "delete", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },
  addUser: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restUser, {
          body: JSON.stringify(payload), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  resetUserPassword: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${getHelperSingle(restUser, payload.id)}/password`, {
          body: JSON.stringify(removeProperties(payload, ['id'])), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  updateUser: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restUser, payload.id), {
          body: JSON.stringify(removeProperties(payload, ['id'])), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },

  // END USER
  // EXERCISES
  getExerciseById: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restExercises(payload.categoryId, payload.phaseId), payload.exerciseId), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  getExercises: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restExercises(payload.categoryId, payload.phaseId), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  addExercise: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restExercises(payload.categoryId, payload.phaseId), {
          body: JSON.stringify(removeProperties(payload, ['categoryId', 'phaseId'])), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },  
  updateExercise: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restExercises(payload.categoryId, payload.phaseId), payload.exerciseId), {
          body: JSON.stringify(removeProperties(payload, ['categoryId', 'phaseId', 'exerciseId'])), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  deleteExercise: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restExercises(payload.categoryId, payload.phaseId), payload.exerciseId), {
          method: "delete", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },
  saveExercises: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(saveExercisesApi, {
          body: JSON.stringify(payload), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  // END EXERCISES

  // PHASES
  getPhaseById: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restPhases(payload.categoryId), payload.phaseId), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  getPhases: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restPhases(payload), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  addPhase: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restPhases(payload.categoryId), {
          body: JSON.stringify(removeProperties(payload, ['categoryId'])), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },  
  updatePhase: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restPhases(payload.categoryId), payload.phaseId), {
          body: JSON.stringify(removeProperties(payload, ['categoryId', 'phaseId'])), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  deletePhase: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restPhases(payload.categoryId), payload.phaseId), {
          method: "delete", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },
  savePhases: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(savePhasesApi, {
          body: JSON.stringify(payload), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  // END PHASES

  // CATEGORIES
  getCategoryById: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restCategory, payload), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  getCategories: (store) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restCategory, {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  deleteCategory: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restCategory, payload), {
          method: "delete", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    })
  },
  saveCategories: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(saveCategoriesApi, {
          body: JSON.stringify(payload), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  addCategory: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restCategory, {
          body: JSON.stringify(payload), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },  
  updateCategory: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperSingle(restCategory, payload.id), {
          body: JSON.stringify(removeProperties(payload, ['id'])), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  cloneCategories: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${getHelperSingle(restCategory, payload.id)}/clone`, {
          body: JSON.stringify(removeProperties(payload, ['id'])), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  // END CATEGORIES

  // ADVICES
  getAdvice: (store) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restAdvice, {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  updateAdvice: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restAdvice, {
          body: JSON.stringify(payload), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  // END ADVICES

  // REMINDER
  getReminders: (store) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restReminders, {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  updateReminders: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restReminders, {
          body: JSON.stringify(payload), method: "put", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },
  // END REMINDER

  // STATISTICS
  getStatisticsByUserId: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(getHelperProps(`${restUser}/${payload.id}/activity`, removeProperties(payload, ['id'])), {
          method: "get", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })
  },
  // END STATISTICS

  // NOTIFICATIONS
  sendNotification: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restNotifications, {
          body: JSON.stringify(payload), method: "post", headers: addAuthorizationToken(store.state.token, {
            'content-type': 'application/json',
          })
        });
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  },  
  // END NOTIFICATIONS

  // ASSETS
  addAsset: (store, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(restAssets, {
          body: payload, method: "post", headers: addAuthorizationToken(store.state.token, {})
        });
        const result = await response.json();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  },  
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})