<template>
	<CRow>
		<CCol col="12">
			<CRow>
				<CCol
					:lg="12"
					class="mb-sm-2 mb-0"
				>
					<CInput
						label="Nome categoria"
						:value="validatorObj.name.$model"
						@update:value="setName"
						:isValid="requiredValidTouched(validatorObj.name)"
					>
					</CInput>
				</CCol>
			</CRow>
			<div class="footer-actions">
				<CButton
					color="sdAzure"
					@click="eventHandler"
					:disabled="validatorObj.name.$invalid"
				>{{activeCategory ? 'Aggiorna' : 'Crea'}}</CButton>
			</div>
		</CCol>
	</CRow>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, deleteModalOptions, pushToast } from "../../utilities/utilities";
import { mapActions } from "vuex";

export default {
	name: 'Categoria',
	created() {
		if (this.activeCategory) {
			this.$store.commit('loadState', true);
			this.$store.dispatch("getCategoryById", this.activeCategory).then(category => {
				this.name = category.name;
				this.rank = category.rank;
				this.$store.commit('loadState', false);
			});
		}
	},
	validations() {
		return {
			name: { required },
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	props: {
		params: {
			type: Object,
			default: undefined
		}
	},
	data() {
		return {
			name: "",
		}
	},
	computed: {
		activeCategory() {
			return this.params && this.params.isNew ? undefined : this.$store.state.activeCategory;
		},
		lastCategoryRank() {
			return this.$store.state.lastCategoryRank;
		},
		categories() {
			return this.$store.state.categories;
		}
	},
	methods: {
		...mapActions(["addCategory", "updateCategory"]),
		setName(value) {
			this.validatorObj.name.$model = value;
		},
		eventHandler() {
			this.activeCategory ? this.updateCategory() : this.addCategory();
		},
		addCategory() {
			this.$store.dispatch('addCategory', {
				name: this.validatorObj.name.$model,
				rank: this.lastCategoryRank && this.lastCategoryRank + 1 || 1,
			}).then(x => {
				this.$store.commit('pushNewCategory', x);
				this.deleteModalOptions();
			});
		},
		updateCategory() {
			this.$store.dispatch('updateCategory', {
				id: this.activeCategory,
				name: this.validatorObj.name.$model,
			}).then(data => {
				const index = this.categories.items.findIndex(x => x.id === data.id);
				this.$store.commit('updateExistingCategory', { index: index, item: data });
				this.deleteModalOptions();
				this.pushToast({ title: 'Aggiornato', body: 'Categoria aggiornata', color: 'success' });
			});
		},
		requiredValidTouched,
		deleteModalOptions,
		pushToast
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>